import React, {useState , useEffect} from 'react';
import "../css/component css/Navbar.css"
import logo from "../images/logo.png"



export default function AdminNavbar() {
        const [bgColor, setBgColor] = useState('transparent');
        const [sideMenu , setSideMenu] = useState(false);
      
        const displaySideMenu = () => {
          setSideMenu(true);
        }
        const hideSideMenu = () => {
          setSideMenu(false);
        }
      
        useEffect(() => {
          const handleScroll = () => {
            if (window.scrollY > 50) {
              setBgColor('white');
            } else {
              setBgColor('transparent');
            }
          };
          window.addEventListener('scroll', handleScroll);
          return () => {
            window.removeEventListener('scroll', handleScroll);
          };
        }, []);
  return (
    <div className='navbar' id='navbar' style={{ backgroundColor: bgColor }}>
      <div className="navbar-center">
        <img src={logo} alt="" />
      </div>
    </div>
  )
}
