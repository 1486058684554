import { BrowserRouter, Route, Routes , useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import ServicePage from "./pages/ServicePage";
import Dashboard from "./Admin/Dashboard";
import { useEffect, useState } from "react";
import AdminNavbar from "./components/AdminNavbar";
import ErrorPage from "./pages/ErrorPage";


function App() {
  return (
    <>
      <BrowserRouter>
        {/* {admin ? <AdminNavbar/> :<Navbar/>} */}
        <NavBarSwitcher/>
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/contact" element={<ContactPage/>}/>
          <Route path="/about" element={<AboutPage/>}/>
          <Route path="/services" element={<ServicePage/>}/>
          <Route path="*" element={<ErrorPage/>}/>

          <Route path="/dashboard_Admin=truexSgAubCklHsdIdfN_dsJopHdsA_23" element={<Dashboard/>}/>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}

function NavBarSwitcher() {
  const location = useLocation();
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    setAdmin(location.pathname === "/dashboard_Admin=truexSgAubCklHsdIdfN_dsJopHdsA_23");
  }, [location.pathname]);

  return admin ? <AdminNavbar /> : <Navbar />;
}

export default App;
