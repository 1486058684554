import React , {useEffect, useState} from 'react'
import "../css/page css/ContactPage.css"
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import TestimonialSection from '../components/TestimonialSection';
import GppGoodIcon from '@mui/icons-material/GppGood';
import error from "../images/404.jpg";


export default function ContactPage() {

    const [formData , setFormData] = useState({name:"" , email:"" , query:""});
    const [successScreen , setSuccessScreen] = useState(false)

    const handleChange = (e) => {
        setFormData({...formData , [e.target.name]:e.target.value});
    }

    const handleSubmit = async(e) => {
        e.preventDefault()

        const response = await fetch("https://revital-physio-backend.onrender.com/api/create-query" , {
            method : 'POST' , 
            headers : {
                'content-type' : 'application/json'
            },
            body : JSON.stringify(formData)
        })

        const json = await response.json()

        if(json.success){
            setSuccessScreen(true);
        }
    }

    useEffect(() => {
        window.scrollTo(0,0);
    } , [])

    const handleReload = () => {
        window.location.reload()
    }
  return (
    <div className='contact-page'>
    {/* <div className="error-screen">
                <img src={error} alt="" />
            </div> */}
      <div className="contact-page-landing-section">
        <div className="contact-page-landing-section-left">
            <div className="connecting-card">
                <div className="connecting-card-left">
                    <CallIcon className='connecting-card-icon'/>
                </div>
                <div className="connecting-card-right">
                    <h5>Contact Number</h5>
                    <p>+91 8742920558</p>
                </div>
            </div>
            <div className="connecting-card">
                <div className="connecting-card-left">
                    <EmailIcon className='connecting-card-icon'/>
                </div>
                <div className="connecting-card-right">
                    <h5>Email Address</h5>
                    <p>sachin03.nic@gmail.com</p>
                </div>
            </div>
            <div className="connecting-card">
                <div className="connecting-card-left">
                    <LocationOnIcon className='connecting-card-icon'/>
                </div>
                <div className="connecting-card-right">
                    <h5>Address</h5>
                    <p>Princess Park , Faridabad F-Block</p>
                </div>
            </div>
        </div>
        <div className="contact-page-landing-section-right">
            <form action="" className="contact-form" onSubmit={handleSubmit}>
                <h4>Contact Us</h4>
                <div className="input-box">
                    <p>Enter Name</p>
                    <input type="text" className="input-field" required name='name' value={formData.name} onChange={handleChange} />
                </div>
                <div className="input-box">
                    <p>Enter Email / Contact Number</p>
                    <input type="text" className="input-field" required name='email' value={formData.email} onChange={handleChange}/>
                </div>
                <div className="input-box">
                    <p>Enter Query</p>
                    <textarea name="query" id="" className='input-field' required value={formData.query} onChange={handleChange}></textarea>
                </div>
                <input type="submit" value="Send Message" className='btn-a' />
            </form>
            {successScreen && <div className="success-screen delete-screen">
                <div className="success-box delete-box">
                    <GppGoodIcon className='success-icon'/>
                    <h4>Message Sent Successfully !!</h4>
                    <button className="btn-a accept-btn" onClick={handleReload}>OK</button>
                </div>
          </div>}
        </div>
      </div>
      <div className="contact-page-map-section">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224345.89796339086!2d77.04416717106561!3d28.52755441229373!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x52c2b7494e204dce!2sNew%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1716361397119!5m2!1sen!2sin" width="100%" height="300" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <TestimonialSection/>
    </div>
  )
}
