import React from 'react'
import clinic1 from "../images/clinic1.jpg"
import clinic2 from "../images/clinic2.jpg"
import clinic3 from "../images/clinic3.jpg"
import clinic4 from "../images/clinic4.jpg"
import clinic5 from "../images/clinic5.jpg"
import clinic6 from "../images/clinic6.jpg"
import clinic7 from "../images/clinic7.jpg"
import clinic8 from "../images/clinic8.jpg"
import "../css/component css/ClinicSection.css"
export default function ClinicSection() {
  return (
    <div className="clinic-section">
            <h3>Our <span>Clinic Tour</span></h3>
            <p>Explore Revital Physio's modern clinic, featuring advanced equipment and a welcoming atmosphere designed to support your recovery and wellness journey.</p>
            <div className="clinic-section-middle">
                <img src={clinic1} alt="" />
                <img src={clinic2} alt="" />
            </div>
            <div className="clinic-section-bottom">
                <img src={clinic3} alt="" />
                <img src={clinic4} alt="" />
                <img src={clinic5} alt="" />
                <img src={clinic6} alt="" />
                <img src={clinic7} alt="" />
                <img src={clinic8} alt="" />
            </div>
        </div>
  )
}
