import React , {useEffect, useState} from 'react'
import "../css/page css/ServicePage.css"
import serviceLandingImage from "../images/service page landing img.png"
import ServiceCard from '../components/ServiceCard'


export default function ServicePage() {

    const [serviceData , setServiceData] = useState([]);

    
    useEffect(() => {
        const fetchData = async() => {
            const response = await fetch("https://revital-physio-backend.onrender.com/api/read-service" , {
                method : 'GET',
                headers : {
                    'content-type' : 'application/json'
                }
            })
            const json = await response.json()
            setServiceData(json.data);
        }

        fetchData();
    }, [])

    useEffect(() => {
        window.scrollTo(0,0);
    } , [])
  return (
    <div className='service-page'>

        <div className="service-page-landing-section">
            <div className="service-page-landing-section-left">
                <h2><span>Services</span> That We Offer</h2>
                <p>At Revital Physio, we offer a diverse array of physiotherapy services tailored to cater to a wide spectrum of needs, ensuring holistic care for optimal health and wellness.</p>
            </div>
            <div className="service-page-landing-section-right">
                <img src={serviceLandingImage} alt="" />
            </div>
        </div>
            <div className="service-page-second-section">
                {serviceData.map((elem) => {
                    return(
                        <ServiceCard key={elem._id} image={elem.image} title={elem.name} description={elem.description}/>
                    )
                })}
            </div>
    </div>
  )
}
