import React from 'react'
import "../css/page css/ErrorPage.css"
import ErrorImage from "../images/404.jpg"
export default function ErrorPage() {
  return (
    <div className='error-page'>
      <img src={ErrorImage} alt="" />
      <h2>Page Not Found</h2>
    </div>
  )
}
