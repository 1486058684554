import React , {useState} from 'react'
import "../css/component css/ListedQueryCard.css"
import DeleteIcon from '@mui/icons-material/Delete';
import GppGoodIcon from '@mui/icons-material/GppGood';

export default function ListedQueryCard({id ,name , email , query}) {
    const [successScreen , setSuccessScreen] = useState(false)

    const deleteQuery = async(id) => {
        const response = await fetch(`https://revital-physio-backend.onrender.com/api/delete-query/${id}` , {
            method:'DELETE' , 
            headers:{
                'content-type' : 'application/json'
            }
        })
        const json = await response.json()
        if(json.success){
            setSuccessScreen(true)
        }
    }
    
    const handleReload = () => {
        window.location.reload()
    }
    return (
        <div className="listed-query-card">
            <div className="listed-query-card-top">
                <h5>{name}</h5>
                <h6>{email}</h6>
            </div>
            <div className="listed-query-card-bottom">
                <p>{query}</p>
            </div>
            <DeleteIcon className='delete-icon' onClick={() => {deleteQuery(id)}}/>
            {successScreen && <div className="success-screen delete-screen">
                <div className="success-box delete-box">
                    <GppGoodIcon className='success-icon'/>
                    <h4>Message Deleted Successfully !!</h4>
                    <button className="btn-a accept-btn" onClick={handleReload}>OK</button>
                </div>
          </div>}
        </div>
    )
}
