import React, { useState, useEffect } from 'react';
import "../css/component css/Navbar.css"
import logo from "../images/logo.png"
import smLogo from "../images/sm-logo.png"
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';



export default function Navbar() {
  const [bgColor, setBgColor] = useState('transparent');
  const [sideMenu , setSideMenu] = useState(false);

  const displaySideMenu = () => {
    setSideMenu(true);
  }
  const hideSideMenu = () => {
    setSideMenu(false);
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setBgColor('white');
      } else {
        setBgColor('transparent');
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className='navbar' id='navbar' style={{ backgroundColor: bgColor }}>
      <div className="navbar-center">
        <img src={logo} alt="" />
      </div>
      <div className="navbar-left nav-items">
        <Link to="/" className='link-a'>Home</Link>
        <Link to="/about"  className='link-a'>About</Link>
        <Link to="/services"  className='link-a'>Service</Link>
        <Link to="/contact"  className='btn-a'>Book Appointment</Link>
      </div>
      <div className="navbar-right nav-items">
          <MenuIcon className='menu-icon' onClick={displaySideMenu}/>
      </div>
      <a href='https://wa.me/919315438978' target='_blank' className="whatsapp-box">
        <WhatsAppIcon className='whatsapp-icon'/>
      </a>
      {sideMenu && <div className="side-menu">
        <img src={smLogo} alt="" />
        <Link to="/" className='link-a' onClick={hideSideMenu}>Home</Link>
        <Link to="/about"  className='link-a' onClick={hideSideMenu}>About</Link>
        <Link to="/services"  className='link-a' onClick={hideSideMenu}>Service</Link>
        <Link to="/contact"  className='btn-a' onClick={hideSideMenu}>Book Appointment</Link>
        <CloseIcon className='close-icon' onClick={hideSideMenu}/>
      </div>}
    </div>
  )
}
