import React from 'react'
import "../css/component css/TestimonialCard.css"
export default function TestimonialCard(props) {
  return (
    <div className='testimonial-card'>
        <img src={props.image} alt="" />
        <div className="testimonial-card-content">
            <h5>{props.name}</h5>
            <p>{props.feedback}</p>
        </div>
    </div>
  )
}
