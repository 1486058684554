import React from 'react'
import "../css/component css/ServiceCard.css"
export default function ServiceCard(props) {
    return (
        <div className="service-card">
            <div className="service-card-content">
                <h4>{props.title}</h4>
                <p>{props.description}</p>
            </div>
            <img src={props.image} alt="" />
        </div>
    )
}
