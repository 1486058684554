import React, { useState } from 'react'
import "../css/component css/ListedServiceCard.css"
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GppGoodIcon from '@mui/icons-material/GppGood';

export default function ListedServiceCard(props) {
    const [deleteScreen , setDeleteScreen] = useState(false)
    const [successScreen , setSuccessScreen] = useState(false);

    const displayDeleteScreen = () => {
        setDeleteScreen(true);
    }
    const hideDeleteScreen = () => {
        setDeleteScreen(false)
    } 

    const deleteService = async(id) => {
        const response = await fetch(`https://revital-physio-backend.onrender.com/api/delete-service/${id}` , {
            method : 'DELETE',
            headers : {
                'content-type' : 'application/json'
            }
        })
        const json = await response.json();
        if(json.success){
            setSuccessScreen(true)
        }
    }

    const handleReload = () => {
        window.location.reload()
    }

    return (
        <div className="listed-service-card">
            <img src={props.image} alt="" />
            <div className="listed-service-card-content">
                <h5>{props.name}</h5>
                <p>{props.description}</p>
                <div className="listed-service-card-content-bottom">
                    {/* <EditIcon className='edit-icon'/> */}
                    <DeleteIcon className='delete-icon' onClick={displayDeleteScreen}/>
                </div>
            </div>
            {deleteScreen && <div className="delete-screen">
                <div className="delete-box">
                    <h4>Do You Want To Delete This Service Permanently ?</h4>
                    <div className="delete-box-options">
                        <button className="btn-a accept-btn" onClick={() => {deleteService(props.id)}}>YES</button>
                        <button className="btn-a delete-btn" onClick={hideDeleteScreen}>NO</button>
                    </div>
                </div>
            </div>
            }
            {successScreen && <div className="success-screen delete-screen">
                <div className="success-box delete-box">
                    <GppGoodIcon className='success-icon'/>
                    <h4>Item Deleted Successfully!</h4>
                    <button className="btn-a accept-btn" onClick={handleReload}>OK</button>
                </div>
            </div>}
        </div>
    )
}
