import React from 'react'
import covid1 from "../images/covid1.jpg"
import covid2 from "../images/covid2.jpg"
import covid3 from "../images/covid3.jpg"
import covid4 from "../images/covid4.jpg"
import "../css/component css/CovidSection.css"
import { Link } from 'react-router-dom'
export default function CovidSection() {
    return (
        <div className="covid-section">
            <h3>Post <span>COVID-19</span> Rehabilitation</h3>
            <p>Don’t leave the battle halfway when you are only a step away from normalcy. Opt for Post-Covid Rehabilitation and get back to a healthier version of you.</p>
            <Link to="/contact" className="btn-a">Get Consultation</Link>
            <div className="covid-section-bottom">
                <img src={covid1} alt="" />
                <img src={covid2} alt="" />
                <img src={covid3} alt="" />
                <img src={covid4} alt="" />
            </div>
        </div>
    )
}
