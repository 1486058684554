import React , {useEffect} from 'react'
import "../css/page css/HomePage.css"
import landingImg from "../images/homepage-landing-section-image.png"
import service1 from "../images/service1.jpg"
import service2 from "../images/homeopathy icon.png"
import service3 from "../images/lab test icon.png"
import service4 from "../images/nurse icon.png"
import service5 from "../images/medical equipment icon.png"
import service6 from "../images/service2.jpg"
import aboutSection from "../images/about-section.png"
import CovidSection from '../components/CovidSection'
import phyiotherapy from "../videos/physiotherapy.mp4"
import smLogo from "../images/sm-logo.png"
import TestimonialSection from '../components/TestimonialSection'
import ClinicSection from '../components/ClinicSection'
import error from "../images/404.jpg";
import { Link } from 'react-router-dom'
export default function HomePage() {
    useEffect(() => {
        window.scrollTo(0,0);
    } , [])
    return (
        <div className='homepage'>

            {/* <div className="error-screen">
                <img src={error} alt="" />
            </div> */}

            <div className="landing-section">
                <video src={phyiotherapy} autoPlay muted loop></video>
                <img src={smLogo} alt="" />
                <h4>Welcome To</h4>
                <h1>Revital <span>Physio</span></h1>
                <p>Welcome to Revital Physio. We provide personalized physiotherapy services to help you recover from injury, manage pain, and enhance physical function with our skilled and experienced team.</p>
            </div>
            <div className="homepage-landing-section">
                <div className="homepage-landing-section-left">
                    <h2>We Take <span> Care</span> Of Your</h2>
                    <div class="wrapper">
                        <ul class="dynamic-txts">
                            <li><span>Physiotherapy</span></li>
                            <li><span>Wound Dressing</span></li>
                            <li><span>Lab Testing</span></li>
                            <li><span>Nursing</span></li>
                        </ul>
                    </div>
                    <p>At Revital Physio, your well-being is our top priority. We understand that every individual is unique, and so are their healthcare needs. Our approach is centered around you, ensuring that every aspect of your treatment is personalized and designed to help you achieve the best possible outcomes.</p>
                    <a href="#serviceSection" className="btn-a">Explore Now</a>
                </div>
                <div className="homepage-landing-section-right">
                    <img src={landingImg} alt="" />
                </div>
            </div>
            <div className="our-services" id="serviceSection">
                <div className="our-services-top">
                    <h3>Our <span>Services</span></h3>
                </div>
                <div className="our-services-middle">
                    <Link to="/contact" className="static-service-card">
                        <img src={service1} alt="" />
                        <h4>Physiotherapy at
                            Revital Physio Clinic/Home</h4>
                        <p>Quality Physiotherapy services at home as well as at clinic.

                        </p>
                    </Link>
                    <Link to="/contact" className="static-service-card">
                        <img src={service2} alt="" />
                        <h4>Homeopathy Consultaion & Medicines</h4>
                        <p>Wound Care Dressing for Bed sores, Diabetic foot, Burns, Post surgical wounds by experts at Home.</p>
                    </Link>
                    <Link to="/contact" className="static-service-card">
                        <img src={service3} alt="" />
                        <h4>Path test / Lab test at Revital Clinic / Home</h4>
                        <p>Negative Pressure Wound Therapy (NPWT) is very effective in fast wound healing.</p>
                    </Link>
                    <Link to="/contact" className="static-service-card">
                        <img src={service4} alt="" />
                        <h4>Nursing / Attendant Care</h4>
                        <p>Quality Physiotherapy services at home as well as at clinic.
                        </p>
                    </Link>
                    <Link to="/contact" className="static-service-card">
                        <img src={service5} alt="" />
                        <h4>Physiotherapy / medical equipment</h4>
                        <p>Wound Care Dressing for Bed sores, Diabetic foot, Burns, Post surgical wounds by experts at Home.</p>
                    </Link>
                    <Link to="/contact" className="static-service-card">
                        <img src={service6} alt="" />
                        <h4>Wound dressing at Revital Clinic / home</h4>
                        <p>Negative Pressure Wound Therapy (NPWT) is very effective in fast wound healing.</p>
                    </Link>
                </div>
                <div className="our-services-bottom">
                    <Link to="/services" className="btn-a">View All Services</Link>

                </div>
            </div>
            <div className="about-section">
                <div className="about-section-left">
                    <p>Revital Physio is a premier physiotherapy clinic dedicated to providing exceptional care to our community. Founded on the principles of compassion, expertise, and innovation, we strive to be a beacon of hope and healing for those in need of physical rehabilitation.</p>
                    <p>At Revital Physio, our mission is to help you revitalize your life through improved movement, reduced pain, and enhanced overall well-being. We are dedicated to supporting you every step of the way on your path to health and recovery.</p>
                    <p>Our clinic is equipped with the latest technology and a comfortable, welcoming environment to ensure you feel at ease during your visits. We believe in continuous learning and stay updated with the latest advancements in physiotherapy to provide you with the best possible care.</p>
                    <Link to="/about" className="btn-a">Know More About Us</Link>
                </div>
                <div className="about-section-right">
                    <img src={aboutSection} alt="" />
                </div>
            </div>
            <CovidSection />
            <ClinicSection/>
            <TestimonialSection/>
        </div>
    )
}
