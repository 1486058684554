import React , {useEffect} from 'react'
import "../css/page css/AboutPage.css"
import aboutImg from "../images/about-page-landing-img.png"
import tvScreen from "../videos/tv screen.mp4"
import doctor from "../images/doctor.png"
import ClinicSection from '../components/ClinicSection'
import { Link } from 'react-router-dom'
import error from "../images/404.jpg";


export default function AboutPage() {
    useEffect(() => {
        window.scrollTo(0,0);
    } , [])
  return (
    <div className='about-page'>
    
            {/* <div className="error-screen">
                <img src={error} alt="" />
            </div> */}

        <div className="about-page-landing-section">
            <div className="about-page-landing-section-left">
                <h2>We Value <span>Your Trust</span> Over Everything</h2>
                <p>At Revital Physio, we deeply value the trust you place in us for your health and wellness needs. Our team is dedicated to building strong, lasting relationships with our clients</p>
            </div>
            <div className="about-page-landing-section-right">
                <img src={aboutImg} alt="" />
            </div>
        </div>
        <div className="about-page-second-section">
            <h3>Your well-being is our commitment! <span> your trust, our honor</span>. Welcome to Revital Physio, where health matters most</h3>
            <p>At Revital Physio, we honor your trust by prioritizing your well-being above all else. Welcome to a place where personalized care meets dedication, and your journey to health begins.!</p>
            <Link to="/contact" className='btn-a'>Get Consultation</Link>
            <video src={tvScreen} autoPlay muted loop></video>
        </div>
        <div className="doctor-section">
            <div className="doctor-section-left">
                <h3>I'm <span>Dr. RANJEET</span> (PT)</h3>
                <p>BPT (DU) , PTT (SAFDARJUNG)</p>
                <p>I am a board-certified physiotherapist dedicated to improving the lives of my patients through personalized care and evidence-based treatments. With a Bachelor of Science in Physiotherapy and a Doctor of Medicine degree from [University Name], I bring a wealth of knowledge and expertise to my practice.</p>
                <p>Throughout my career, I have remained committed to staying at the forefront of advancements in physiotherapy. My passion for helping others achieve optimal health and wellness has driven me to pursue additional certifications in specialized areas such as sports medicine, orthopedics, and rehabilitation.</p>
            </div>
            <div className="doctor-section-right">
                <img src={doctor} alt="" />
            </div>
        </div>
        <ClinicSection/>
    </div>
  )
}
