import React from 'react'
import "../css/component css/TestimonialSection.css"
import TestimonialCard from './TestimonialCard'
import men1 from "../images/men1.jpg"
import men2 from "../images/men2.jpg"
import men3 from "../images/men3.jpg"
import men4 from "../images/men4.jpg"
import girl1 from "../images/girl1.jpg"
import girl2 from "../images/girl2.jpg"
import girl3 from "../images/girl3.jpg"
import girl4 from "../images/girl4.jpg"

export default function TestimonialSection() {
  return (
    <div className='testimonial-section'>
        <div className="testimonial-section-left">
            <h3>What Our <span>Clients Says</span></h3>
            <p>At Revital Physio, we take immense pride in the positive feedback we receive from our clients. Our testimonials reflect the transformative experiences of individuals who have entrusted us with their physiotherapy needs.</p>
            <p>Each story highlights the dedication and expertise of our team, showcasing the personalized care and effective treatments that set us apart. From injury recovery and pain management to enhanced mobility and overall well-being</p>
        </div>
        <div className="testimonial-section-right">
            <TestimonialCard image={men1} name="Sachin Jha" feedback="Revital Physio transformed my recovery journey. The personalized care and attention to detail made all the difference. Highly recommend!"/>
            <TestimonialCard image={girl3} name="Riya" feedback="The team at Revital Physio helped me regain my mobility after surgery. Their expertise and compassion are unmatched"/>
            <TestimonialCard image={girl4} name="Jyoti Singh" feedback="I experienced chronic pain for years, but thanks to Revital Physio, I'm finally pain-free. Their holistic approach truly works."/>
            <TestimonialCard image={men2} name="Rahul Sharma" feedback="Professional, caring, and effective. Revital Physio is the best choice for anyone in need of physiotherapy."/>
            <TestimonialCard image={men3} name="Shivam Rajput" feedback="Revital Physio gave me my life back. The tailored treatment plans and supportive staff made a huge difference in my recovery."/>
            <TestimonialCard image={girl2} name="Nandini" feedback="I was impressed by the state-of-the-art facilities and the expertise of the physiotherapists at Revital Physio. Highly recommend to anyone!"/>
            <TestimonialCard image={girl1} name="Arpita" feedback="Thanks to Revital Physio, I can enjoy my favorite activities again without pain. Their dedication to patient care is exceptional."/>
            <TestimonialCard image={men4} name="Sameer singh" feedback="From the moment I walked in, I knew I was in good hands. Revital Physio's team is professional, kind, and incredibly effective."/>
        </div>
    </div>
  )
}
