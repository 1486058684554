import React, { useEffect, useState } from 'react'
import "./Dashboard.css"
import gif3d from "../gif/3d.gif"
import ListedServiceCard from '../components/ListedServiceCard'
import testImage from "../images/medical equiptment.jpg"
import ListedQueryCard from '../components/ListedQueryCard'
import GppGoodIcon from '@mui/icons-material/GppGood';
import error from "../images/404.jpg";



export default function Dashboard() {
  const [serviceData, setServiceData] = useState({ image: '', name: '', descrition: '' })
  const [readableServiceData, setReadableServiceData] = useState([]);
  const [readableQueryData , setReadableQueryData] = useState([])
  const [successScreen , setSuccessScreen] = useState(false)

  const handleServiceChange = (e) => {
    setServiceData({ ...serviceData, [e.target.name]: e.target.value });
  }

  const handleServiceSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch("https://revital-physio-backend.onrender.com/api/create-service", {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify(serviceData)
    })
    const json = await response.json();
    if (json.success) {
      setSuccessScreen(true)
    }
  }

  useEffect(() => {
    const readServiceData = async () => {
      try {
        const response = await fetch("https://revital-physio-backend.onrender.com/api/read-service", {
          method: 'GET',
          headers: {
            'content-type': 'application/json'
          }
        })
        const json = await response.json()
        if (json.success) {
          setReadableServiceData(json.data);
        }
      } catch (err) {
        console.log(err);
      }
    }

    readServiceData();

  }, [])

  useEffect(() => {
    const readQueryData = async() => {
      try{
        const response = await fetch("https://revital-physio-backend.onrender.com/api/read-query" , {
          method:'GET', 
          headers : {
            'content-type' : 'application/json'
          }
        })

        const json = await response.json();

        if(json.success){
          setReadableQueryData(json.data);
        }

      }catch(err){
        console.log(err);
      }
    }

    readQueryData();
  } , [])

  const reversedServiceData = readableServiceData.slice().reverse();

  const truncateDescription = (description) => {
    const words = description.split(' ');
    if (words.length > 30) {
      return words.slice(0, 30).join(' ') + '...';
    }
    return description;
  };

  const handleReload = () => {
    window.location.reload()
}


  return (
    <div className='dashboard'>
     <div className="error-screen">
                <img src={error} alt="" />
            </div>
      <div className="dashboard-landing-section">
        <div className="dashboard-landing-section-left">
          <h4>Welcome To</h4>
          <h2>Admin Dashboard</h2>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Harum ipsa quo eius illum natus nobis omnis sequi fuga, officia unde et. Eaque deleniti nobis inventore, at quidem amet eligendi commodi.</p>
          <a href="#" className="btn-a">Explore Features</a>
        </div>
        <div className="dashboard-landing-section-right">
          <img src={gif3d} alt="" />
        </div>
      </div>



      <div className="manage-service-section">
        <div className="manage-service-section-left">
          <form className="service-form" onSubmit={handleServiceSubmit}>
            <h4>Add Service</h4>
            <div className="input-box">
              <p>Image URL</p>
              <input type="text" className="input-field" name='image' value={serviceData.image} onChange={handleServiceChange} required />
            </div>
            <div className="input-box">
              <p>Name Of The Service</p>
              <input type="text" className="input-field" name='name' value={serviceData.name} onChange={handleServiceChange} required />
            </div>
            <div className="input-box">
              <p>Service Description</p>
              <textarea className='input-field' name='description' value={serviceData.description} onChange={handleServiceChange} required></textarea>
            </div>
            <input type="submit" value="Add New Service" className='btn-a' />
          </form>
          {successScreen && <div className="success-screen delete-screen">
                <div className="success-box delete-box">
                    <GppGoodIcon className='success-icon'/>
                    <h4>New Service Added Successfully</h4>
                    <button className="btn-a accept-btn" onClick={handleReload}>OK</button>
                </div>
          </div>}
        </div>
        <div className="manage-service-section-right">
          <h3>Existing Service</h3>
          <div className="scrollable-section">
            {  
              reversedServiceData.map((elem) => (
                <ListedServiceCard key={elem._id} id={elem._id} image={elem.image} name={elem.name} description={truncateDescription(elem.description)} />
              )
            )}
          </div>
        </div>
      </div>


      <div className="manage-query-section">
        <h3>Manage Patients <span> Query</span></h3>
        <div className="manage-query-section-bottom">
          {readableQueryData.map((elem) => (
            <ListedQueryCard key={elem._id} name={elem.name} email={elem.email} query={elem.query} id={elem._id} />
          ))}
        </div>
      </div>
    </div>
  )
}
