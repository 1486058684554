import React from 'react'
import "../css/component css/Footer.css"
import smLogo from "../images/sm-logo.png"
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
export default function Footer() {
  return (
    <div className='footer'>
        <div className="footer-top">
            <div className="footer-column" id="fcolumn1">
                <img src={smLogo} alt="" />
                <h4>Revital <span>Physio</span></h4>
            </div>
            <div className="footer-column" id="fcolumn2">
                <h4>CONTACT INFO</h4>
                <p><CallIcon className='icons'/> +91 9315438978</p>
                <p><EmailIcon className='icons'/> revitalphysioindia@gmai.com</p>
                <h4>OPD TIMING</h4>
                <p>Mon-Sat: 09:00 am to 08:00 pm</p>
                <p>Sun: 09:00 am to 03:00 pm</p>
            </div>
            <div className="footer-column" id="fcolumn3">
                <h4>ADDRESS</h4>
                <p>Princess Park , F-Tower 14A Block</p>
                <div className="social-media-row">
                    <a href="https://www.instagram.com/revital.physio/" target='_blank'><InstagramIcon className='social-media-icons'/></a>
                    {/* <a href="#"><YouTubeIcon className='social-media-icons'/></a> */}
                    <a href="https://wa.me/919315438978" target='_blank'><WhatsAppIcon className='social-media-icons'/></a>
                </div>
            </div>
        </div>
        <div className="footer-bottom">
            <h5>Website is Designed & Developed By <a href="https://sachinjha.tech">Sachin Jha</a></h5>
        </div>
    </div>
  )
}
